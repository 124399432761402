<template>
    <div>
        <v-container>
            <div class="text-h5 text-sm-h5 text-md-h4 text-lg-h4 ma-3 text-xl-h4 text-center">
                Categorías de elemento
            </div>

            <v-form :disabled="loading">
                <v-row>
                    <v-col cols="12" sm="7" md="5" lg="5" xl="3">
                        <ElemCatFind 
                            ref="elemCatFind" 
                            v-model="filtros.ElemCatParent" 
                            label="Tipo"
                            :showParentsOnly="true"
                            @change="loadData">
                        </ElemCatFind>
                    </v-col>
                    <v-col cols="12" sm="7" md="5" lg="5" xl="3">
                        <v-text-field
                            class="mb-n7"
                            outlined
                            dense
                            text
                            v-model="filtros.ElemCatNom"
                            label="Nombre"
                            @change="loadData">
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-form>

            <v-btn
                color="primary"
                class="mt-5 mb-3"
                @click="onClickNuevaCategoria">
                Nuevo
            </v-btn>

            <v-data-table
                :headers="headers"
                :items="dataRows"
                :options.sync="options"
                :footer-props="{itemsPerPageOptions: [15, 30, 50, 100]}"
                :server-items-length="totalDataRows"
                :loading="loading"
                @click:row="onClickRowEditarCategoria"
                style="overflow: scroll"
                mobile-breakpoint="0"
                class="elevation-1"
                dense>
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template v-slot:item.Opt="{ item }">
                    <v-btn
                        class="my-1" small
                        style="width:30px; min-width:30px;"
                        :disabled="loading"
                        @click.stop="selectParent(item)">
                        <v-icon>mdi-arrow-down-right</v-icon>
                    </v-btn>
                </template>
            </v-data-table>
        </v-container>
    </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import ElemCatFind from "../components/ElemCatFind.vue";
export default ({
    components: {
        ElemCatFind
    },
    computed: {
        ...mapState(['empId', 'perId', 'urlRaiz'])
    },
    data () {
        return {
            loading: true,
            totalDataRows: 0,
            dataRows: [],
            options: {itemsPerPage: 15},
            headers: [
                { text: 'Código', width: 75, sortable: false, value: 'ElemCatCod' },
                { text: 'Nombre', width:270, sortable: false, value: 'ElemCatNom' },
                { text: 'Observaciones', width: 300, sortable: false, value: 'ElemCatObs' },
                { text: '', width: 50, sortable: false, groupable: false, value: 'Opt', align: 'end' }
            ],
            dtOptionsLoaded: false,
            filtros: {
                ElemCatNom: '',
                ElemCatParent: null
            }
        }
    },
    watch: {
      options: {
        handler () {
          // Evitamos la llamada a la API en la primera carga de las options
          if(!this.dtOptionsLoaded) {
            this.dtOptionsLoaded = true;
            return;
          }
          this.loadData();
        },
        deep: true
      },
      // filtros: {
      //   handler () {
      //     this.loadData();
      //   },
      //   deep: true
      // },
      loading: {
        handler() {
          if (this.loading) {
            this.$refs.elemCatFind.$refs.ElemCatFindCtrl.isMenuActive = false;
          }
        },
        deep: true
      }
    },
    mounted() {
      this.loadData();
    },
    methods: {
      getDataFromApi() {
        this.loading = true;

        var NumRegsPag = this.options.itemsPerPage;
        var NumPag = this.options.page;

        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'GET_DATA_LIST_PAGINATED',
            NumRegsPag: NumRegsPag,
            NumPag: NumPag,
            ElemCatNom: this.filtros.ElemCatNom,
            ElemCatIdParent: this.filtros.ElemCatParent != null ? this.filtros.ElemCatParent.ElemCatId : null,
            ShowNoParentOnly: true,
            EmpId: this.empId
          };
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/elemcat", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const items = result.data.EntsList;
            const total = result.data.EntsTotCount;

            setTimeout(() => {
                this.loading = false;
                resolve({
                    items,
                    total
                })
            }, 1000);
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al cargar las categorías.');
            }
          });
        });
      },
      loadData() {
        this.getDataFromApi()
        .then(data => {
          this.dataRows = data.items;
          this.totalDataRows = data.total;
        });
      },
      selectParent(item) {
        this.filtros.ElemCatParent = {
          ElemCatId: item.ElemCatId,
          ElemCatNom: item.ElemCatNom
        };
        this.loadData();
      },
      setParentData(data) {
        this.$store.state.ElemCatParentData = data;
      },
      onClickNuevaCategoria() {
        this.setParentData(this.filtros.ElemCatParent);
        this.$router.push('/categoria-elemento-nuevo/');
      },
      onClickRowEditarCategoria(item) {
        this.setParentData(null);
        this.$router.push('/categoria-elemento-editar/' + item.ElemCatId);
      }
    } 
})
</script>
